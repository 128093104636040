import React from 'react';

const MesCompetences = () => {
    return (
        <div>
            <ul>
                <li>
                    je peux : vous conseiller. Vous avez sans doute un projet. Une idée. La réaliser passe par une étape en apparence simple mais en réalité délicate : mettre les bons outils face
                </li>
            </ul>
        </div>
    );
};

export default MesCompetences;